/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { DropzoneArea } from 'material-ui-dropzone'
import AutocompleteMui from "../../components/autocomplete-mui/autocomplete-mui";
import AuthorAsyncSelect from "../../components/async-select/athor-async-select";

//--- Action
import * as trackAction from "../../redux/store/track/track.store";
import { getLookupGenres, getLookupMoods, getLookupSoundEffect } from "../../utils/helper";
import * as viVN from "../../language/vi-VN.json";

//--- Material Control
import {
    DialogActions,
    Button,
    TextField,
    DialogContent,
    DialogTitle,
    Dialog,
    makeStyles,
    Typography,
    IconButton,
    Checkbox,
} from "@material-ui/core";

//--- Material Icon
import CloseIcon from "@material-ui/icons/Close";
import SaveIcon from "@material-ui/icons/Save";

//--- Notifications
import ShowNotification from "../../components/react-notifications/react-notifications";
import { NotificationMessageType, APIUrlDefault } from "../../utils/configuration";
import * as appActions from "../../core/app.store";
//--- Styles

const useStyles = makeStyles((theme) => ({
    closeButton: {
        position: "absolute",
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500],
    },
}));

function CreateUpdateTrack(props) {
    const classes = useStyles();

    const {
        isOpen,
        onClose,
        refreshData,
        idSelected,
        dataSelected,
    } = props;

    const [gallery, setGallery] = useState([]);
    const [audioFile, setAudioFile] = useState([]);
    const [audioDemoFile, setAudioDemoFile] = useState([]);
    const [vocal, setVocal] = useState(dataSelected?.vocal ? true : false);
    const [genresModel, setGenresModel] = useState([]);
    const [moodsModel, setMoodsModel] = useState([]);
    const [soundEffectModel, setSoundEffectModel] = useState([]);
    const [authorSelected, setAuthorSelected] = useState({value: dataSelected?.authorId, label: dataSelected?.authorName});
    const [authorModal, setAuthorModal] = useState([]);

    const [genresSelected, setGenresSelected] = useState({id: dataSelected?.genresId, name: dataSelected?.genresName});
    const [moodsSelected, setMoodsSelected] = useState({id: dataSelected?.moodsId, name: dataSelected?.genresName});
    const [soundEffectSelected, setSoundEffectSelected] = useState({id: dataSelected?.soundEffectId, name: dataSelected?.soundEffectName});

    const { register, handleSubmit, setError, errors, clearErrors, setValue } = useForm({
        mode: "all",
        reValidateMode: "onBlur",
    });

    useEffect(() => {
        onGetData();
    }, [])

    const onGetData = () => {
        getLookupGenres().then(res => {
            if (res.content && res.content.length > 0) {
                setGenresModel(res.content)
                setGenresSelected(res.content.filter(x => x.id == dataSelected.genresId)[0])
            }
        });
        getLookupMoods().then(res => {
            if (res.content && res.content.length > 0) {
                setMoodsSelected(res.content.filter(x => x.id == dataSelected.moodsId)[0])
                setMoodsModel(res.content)
            }
        });
        getLookupSoundEffect().then(res => {
            if (res.content && res.content.length > 0) {
                setSoundEffectSelected(res.content.filter(x => x.id == dataSelected.soundEffectId)[0])
                setSoundEffectModel(res.content)
            }
        });
    }

    const onChangeGenres = (value) => {
        setGenresSelected(value);
    }

    const onChangeMoods = (value) => {
        setMoodsSelected(value);
    }

    const onChangeSoundEffect = (value) => {
        setSoundEffectSelected(value);
    }

    const handleChooseAuthor = (data) => {
        setAuthorSelected(data);
    }

    const onSubmit = async (data) => {
        if (!data) {
            return;
        }
        console.log('dấd', data)
        let formData = new FormData();
        idSelected && formData.append("Id", idSelected);
        data.title && formData.append("Title", data.title);
        data.bpm && formData.append("BPM", data.bpm);
        data.duration && formData.append("Duration", data.duration);
        data.price && formData.append("Price", data.price);
        data.type && formData.append("Type", data.type);
        data.description && formData.append("Description", data.description);
        formData.append("Vocal", vocal);

        genresSelected.id && formData.append("GenresId", genresSelected.id)
        moodsSelected.id && formData.append("MoodsId", moodsSelected.id)
        soundEffectSelected.id && formData.append("SoundEffectId", soundEffectSelected.id)
        authorSelected && formData.append("AuthorId", authorSelected.id)

        if (gallery && gallery.length > 0) {
            formData.append("TepTin", gallery[0]);
        } else if (dataSelected?.documentId) {
            idSelected && formData.append("DocumentId", dataSelected.documentId);
        }

        if (audioFile && audioFile.length > 0) {
            formData.append("TepTinNhac", audioFile[0]);
        } else if (dataSelected?.documentId) {
            idSelected && formData.append("FileId", dataSelected.documentId);
        }

        if (audioDemoFile && audioDemoFile.length > 0) {
            formData.append("TepTinDemo", audioDemoFile[0]);
        } else if (dataSelected?.documentId) {
            idSelected && formData.append("FileDemoId", dataSelected.documentId);
        }

        try {
            const { content } = await (idSelected ? trackAction.UpdateTrack(formData) : trackAction.CreateTrack(formData));
            if (content && content.status) {
                ShowNotification(
                    viVN.Success.NewsEditSuccess,
                    NotificationMessageType.Success
                );
                refreshData();
                onClose();
            }
        }
        catch (err) {
            refreshData();
            onClose();
            ShowNotification(
                err.errorMessage,
                NotificationMessageType.Error
            );
        }
    };

    const handleChangeStatus = (event) => {
        event.persist();
        setVocal(event.target.checked);
    }

    const isNumber = (value) => {
        return !isNaN(value) && !isNaN(parseFloat(value));
    };

    return (
        <div>
            <Dialog open={isOpen} onClose={onClose} fullWidth={true} maxWidth="md">
                <DialogTitle disableTypography className="border-bottom">
                    <Typography variant="h6">{idSelected ? 'Chỉnh sửa' : 'Thêm mới'}</Typography>
                    <IconButton
                        aria-label="close"
                        className={classes.closeButton}
                        onClick={onClose}
                    >
                        <CloseIcon />
                    </IconButton>
                </DialogTitle>

                <form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
                    {true && (
                        <DialogContent className="pt-4 pb-2">
                            <div className="form-group">
                                <div className="row">
                                    <div className="col-12">
                                        <label className="text-dark">
                                            Tiêu đề<span className="required"></span>
                                        </label>
                                        <TextField
                                            variant="outlined"
                                            inputRef={register({ required: true, maxLength: 150 })}
                                            name="title"
                                            error={errors.title && errors.title.type === "required"}
                                            fullWidth
                                            type="text"
                                            className=""
                                            inputProps={{ maxLength: 150 }}
                                            defaultValue={dataSelected?.title}
                                            onChange={(e) =>
                                                setValue("title", e.target.value)
                                            }
                                        />
                                        {errors.title && errors.title.type === "required" && (
                                            <span className="error">Trường này là bắt buộc</span>
                                        )}
                                        {errors.title && errors.title.type === "maxLength" && (
                                            <span className="error">Trường này không quá 150 ký tự</span>
                                        )}
                                    </div>
                                </div>
                            </div>
                            <div className="form-group">
                                <div className="row">
                                    <div className="col-12">
                                        <label className="text-dark">Tóm tắt<span className="required"></span></label>
                                        <textarea
                                            name="description"
                                            rows="5"
                                            ref={register({ required: true, maxLength: 500 })}
                                            className={
                                                "form-control" +
                                                (errors.description && errors.description.type === "required"
                                                    ? " is-invalid"
                                                    : "")
                                            }
                                            maxLength="500"
                                            defaultValue={dataSelected?.description}
                                        ></textarea>
                                        {errors.description && errors.description.type === "required" && (
                                            <span className="error">Trường này là bắt buộc</span>
                                        )}
                                        {errors.description && errors.description.type === "maxLength" && (
                                            <span className="error">Trường này không quá 500 ký tự</span>
                                        )}
                                    </div>
                                </div>
                            </div>
                            <div className="form-group">
                                <div className="row">
                                    <div className="col-12 col-md-4 col-lg-4">
                                        <label className="text-dark">
                                            Price<span className="required"></span>
                                        </label>
                                        <TextField
                                            variant="outlined"
                                            fullWidth
                                            inputRef={register({
                                                required: true,
                                                validate: {
                                                    isNumber: (value) => isNumber(value) || 'Trường này chỉ được nhập số',
                                                },
                                            })}
                                            type="text"
                                            min={0}
                                            step={"any"}
                                            name="price"
                                            defaultValue={dataSelected?.price}
                                            error={
                                                errors.price && errors.price.type === "required"
                                            }
                                        />
                                        {errors.price && (errors.price.type === "required") && (
                                            <span className="error">Trường này là bắt buộc</span>
                                        )}
                                        {errors.price && (errors.price.type === "isNumber") && (
                                            <span className="error">{errors.price.message}</span>
                                        )}
                                    </div>
                                    <div className="col-12 col-md-4 col-lg-4">
                                        <label className="text-dark">
                                            Type<span className="required"></span>
                                        </label>
                                        <TextField
                                            variant="outlined"
                                            inputRef={register({ maxLength: 150 })}
                                            name="type"
                                            fullWidth
                                            type="text"
                                            className="form-control"
                                            inputProps={{ maxLength: 150 }}
                                            defaultValue={dataSelected?.type}
                                            onChange={(e) =>
                                                setValue("type", e.target.value)
                                            }
                                        />
                                    </div>
                                    <div className="col-12 col-md-4 col-lg-4">
                                        <label className="text-dark">
                                            Author
                                        </label>
                                        <AuthorAsyncSelect
                                            authorSelected={authorSelected}
                                            handleChooseAuthor={handleChooseAuthor}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="form-group">
                                <div className="row">
                                    <div className="col-12 col-md-4 col-lg-4">
                                        <label className="text-dark">
                                            BPM<span className="required"></span>
                                        </label>
                                        <TextField
                                            variant="outlined"
                                            fullWidth
                                            inputRef={register({
                                                required: true,
                                                validate: {
                                                    isNumber: (value) => isNumber(value) || 'Trường này chỉ được nhập số',
                                                },
                                            })}
                                            type="text"
                                            min={0}
                                            step={"any"}
                                            name="bpm"
                                            defaultValue={dataSelected?.bpm}
                                            error={
                                                errors.bpm && errors.bpm.type === "required"
                                            }
                                        />
                                        {errors.bpm && (errors.bpm.type === "required") && (
                                            <span className="error">Trường này là bắt buộc</span>
                                        )}
                                        {errors.bpm && (errors.bpm.type === "isNumber") && (
                                            <span className="error">{errors.bpm.message}</span>
                                        )}
                                    </div>
                                    <div className="col-12 col-md-4 col-lg-4">
                                        <label className="text-dark">
                                            Duration<span className="required"></span>
                                        </label>

                                        <TextField
                                            variant="outlined"
                                            fullWidth
                                            inputRef={register({
                                                required: true,
                                                validate: {
                                                    isNumber: (value) => isNumber(value) || 'Trường này chỉ được nhập số',
                                                },
                                            })}
                                            type="text"
                                            min={0}
                                            step={"any"}
                                            name="duration"
                                            defaultValue={dataSelected?.duration}

                                            error={
                                                errors.duration && errors.duration.type === "required"
                                            }
                                        />
                                        {errors.duration && errors.duration.type === "required" && (
                                            <span className="error">Trường này là bắt buộc</span>
                                        )}
                                        {errors.duration && (errors.duration.type === "isNumber") && (
                                            <span className="error">{errors.duration.message}</span>
                                        )}
                                    </div>
                                    <div className="col-12  col-md-4 col-lg-4">
                                        <label className="text-dark">Vocal</label>
                                        <Checkbox
                                            checked={vocal}
                                            onChange={handleChangeStatus}
                                            color="primary"
                                            inputProps={{ "aria-label": "secondary checkbox" }}
                                            className="p-0 mt-0 ml-4"
                                        />
                                    </div>
                                </div>
                            </div>

                            <div className="form-group">
                                <div className="row">
                                    <div className="col-12 col-lg-4">
                                        <label className="text-dark">
                                            Genres
                                        </label>
                                        <AutocompleteMui
                                            name="genresId"
                                            listData={genresModel}
                                            handleChange={onChangeGenres}
                                            value={genresSelected}
                                        />
                                    </div>
                                    <div className="col-12 col-lg-4">
                                        <label className="text-dark">
                                            Moods
                                        </label>
                                        <AutocompleteMui
                                            name="moodsId"
                                            listData={moodsModel}
                                            handleChange={onChangeMoods}
                                            value={moodsSelected}
                                        />
                                    </div>
                                    <div className="col-12 col-lg-4">
                                        <label className="text-dark">
                                            Sound Effect
                                        </label>
                                        <AutocompleteMui
                                            name="soundEffectId"
                                            listData={soundEffectModel}
                                            handleChange={onChangeSoundEffect}
                                            value={soundEffectSelected}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="form-group">
                                <div className="row">
                                    <div className="col-12 col-lg-4">
                                        <label className="text-dark fw-6">
                                            Audio file
                                        </label>
                                        <DropzoneArea
                                            filesLimit={1}
                                            acceptedFiles={['.mp3', '.wav', '.ogg', '.flac', '.aac']}
                                            dropzoneText=""
                                            maxFileSize={500000000}
                                            onChange={(files) => setAudioFile(files)}
                                            showFileNames={true}
                                            initialFiles={[dataSelected?.filePath]}
                                        />
                                    </div>
                                    <div className="col-12 col-lg-4">
                                        <label className="text-dark fw-6">
                                            Audio demo file
                                        </label>
                                        <DropzoneArea
                                            filesLimit={1}
                                            acceptedFiles={['.mp3', '.wav', '.ogg', '.flac', '.aac']}
                                            dropzoneText=""
                                            maxFileSize={500000000}
                                            onChange={(files) => setAudioDemoFile(files)}
                                            showFileNames={true}
                                            initialFiles={[dataSelected?.fileDemoPath]}
                                        />
                                    </div>
                                    <div className="col-12 col-lg-4">
                                        <label className="text-dark fw-6">
                                            Avatar
                                        </label>
                                        <DropzoneArea
                                            filesLimit={1}
                                            acceptedFiles={['image/*']}
                                            dropzoneText=""
                                            maxFileSize={500000000}
                                            onChange={(files) => setGallery(files)}
                                            showFileNames={true}
                                            initialFiles={[dataSelected?.documentPath]}
                                        />
                                    </div>
                                </div>
                            </div>

                        </DialogContent>
                    )}

                    <DialogActions className="border-top">
                        <Button
                            type="button"
                            onClick={onClose}
                            variant="contained"
                            startIcon={<CloseIcon />}
                        >
                            Hủy
                        </Button>
                        <Button
                            type="submit"
                            color="primary"
                            variant="contained"
                            startIcon={<SaveIcon />}
                        >
                            Lưu
                        </Button>
                    </DialogActions>
                </form>
            </Dialog>
        </div>
    );
}
const mapDispatchToProps = (dispatch) =>
    bindActionCreators(
        {
            showLoading: appActions.ShowLoading,
        },
        dispatch
    );

export default connect(null, mapDispatchToProps)(CreateUpdateTrack);
