/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { DropzoneArea } from 'material-ui-dropzone'

//--- Action
import * as themesAction from "../../redux/store/themes/themes.store";
import * as viVN from "../../language/vi-VN.json";

//--- Material Control
import {
    DialogActions,
    Button,
    TextField,
    DialogContent,
    DialogTitle,
    Dialog,
    makeStyles,
    Typography,
    IconButton,
} from "@material-ui/core";

//--- Material Icon
import CloseIcon from "@material-ui/icons/Close";
import SaveIcon from "@material-ui/icons/Save";

//--- Notifications
import ShowNotification from "../../components/react-notifications/react-notifications";
import {NotificationMessageType} from "../../utils/configuration";
import * as appActions from "../../core/app.store";
//--- Styles

const useStyles = makeStyles((theme) => ({
    closeButton: {
        position: "absolute",
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500],
    },
}));

function CreateUpdateThemes(props) {
    const classes = useStyles();

    const {
        isOpen,
        onClose,
        refreshData,
        idSelected,
        dataSelected,
    } = props;

    const [gallery, setGallery] = useState([]);

    const { register, handleSubmit, setError, errors, clearErrors, setValue } = useForm({
        mode: "all",
        reValidateMode: "onBlur",
    });

    const onSubmit = async (data) => {
        if (!data) {
            return;
        }

        let formData = new FormData();
        idSelected && formData.append("Id", idSelected);
        data.title && formData.append("Title", data.title);
        data.description && formData.append("Description", data.description);
        if (gallery && gallery.length > 0) {
            formData.append("TepTin", gallery[0]);
        } else if (dataSelected?.documentId) {
            idSelected && formData.append("DocumentId", dataSelected.documentId);
        }

        try {
            const { content } = await (idSelected ? themesAction.UpdateThemes(formData) : themesAction.CreateThemes(formData));
            if (content && content.status) {
                ShowNotification(
                    viVN.Success.NewsEditSuccess,
                    NotificationMessageType.Success
                );
                refreshData();
                onClose();
            }
        }
        catch (err) {
            refreshData();
            onClose();
            ShowNotification(
                err.errorMessage,
                NotificationMessageType.Error
            );
        }
    };

    return (
        <div>
            <Dialog open={isOpen} onClose={onClose} fullWidth={true} maxWidth="md">
                <DialogTitle disableTypography className="border-bottom">
                    <Typography variant="h6">{idSelected ? 'Chỉnh sửa' : 'Thêm mới'}</Typography>
                    <IconButton
                        aria-label="close"
                        className={classes.closeButton}
                        onClick={onClose}
                    >
                        <CloseIcon />
                    </IconButton>
                </DialogTitle>

                <form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
                    {true && (
                        <DialogContent className="pt-4 pb-2">
                            <div className="form-group">
                                <label className="text-dark">
                                    Tiêu đề<span className="required"></span>
                                </label>
                                <TextField
                                    inputRef={register({ required: true, maxLength: 150 })}
                                    name="title"
                                    error={errors.title && errors.title.type === "required"}
                                    fullWidth
                                    type="text"
                                    className="form-control"
                                    inputProps={{ maxLength: 150 }}
                                    defaultValue={dataSelected?.title}
                                    onChange={(e) =>
                                        setValue("title", e.target.value)
                                    }
                                />
                                {errors.title && errors.title.type === "required" && (
                                    <span className="error">Trường này là bắt buộc</span>
                                )}
                                {errors.title && errors.title.type === "maxLength" && (
                                    <span className="error">Trường này không quá 150 ký tự</span>
                                )}
                            </div>
                            <div className="form-group">
                                <label className="text-dark">Tóm tắt<span className="required"></span></label>
                                <textarea
                                    name="description"
                                    rows="5"
                                    ref={register({ required: true, maxLength: 500 })}
                                    className={
                                        "form-control" +
                                        (errors.description && errors.description.type === "required"
                                            ? " is-invalid"
                                            : "")
                                    }
                                    maxLength="500"
                                    defaultValue={dataSelected?.description}
                                ></textarea>
                                {errors.description && errors.description.type === "required" && (
                                    <span className="error">Trường này là bắt buộc</span>
                                )}
                                {errors.description && errors.description.type === "maxLength" && (
                                    <span className="error">Trường này không quá 500 ký tự</span>
                                )}

                            </div>
                            <div className="form-group">
                                <div className="row">
                                    <div className="col-12">
                                        <label className="text-dark fw-6">
                                            Ảnh avatar
                                        </label>
                                        <DropzoneArea
                                            filesLimit={1}
                                            acceptedFiles={['image/*']}
                                            maxFileSize={500000000}
                                            onChange={(files) => setGallery(files)}
                                            showFileNames={true}
                                            initialFiles={[dataSelected?.documentPath]}
                                        />
                                    </div>
                                </div>
                            </div>

                        </DialogContent>
                    )}

                    <DialogActions className="border-top">
                        <Button
                            type="button"
                            onClick={onClose}
                            variant="contained"
                            startIcon={<CloseIcon />}
                        >
                            Hủy
                        </Button>
                        <Button
                            type="submit"
                            color="primary"
                            variant="contained"
                            startIcon={<SaveIcon />}
                        >
                            Lưu
                        </Button>
                    </DialogActions>
                </form>
            </Dialog>
        </div>
    );
}
const mapDispatchToProps = (dispatch) =>
    bindActionCreators(
        {
            showLoading: appActions.ShowLoading,
        },
        dispatch
    );

export default connect(null, mapDispatchToProps)(CreateUpdateThemes);
